(function () {
    'use strict';

    /**
     aflAddItem Directive
     */
    angular.module('aerosApp').directive('aflAddItem', aflAddItem);

    aflAddItem.$inject = ['$state', 'ToolsDefinitionService', '$rootScope', 'aflToolTypeModal'];
    function aflAddItem($state, ToolsDefinitionService, $rootScope, aflToolTypeModal) {
        return {
            restrict: 'E',
            templateUrl: '/static/templates/directive/afl-add-item.html',
            link: link
        };

        function link(scope) {
            var eventName = $state.params.presetType ?
                'addToolPreset' : 'addToolLibrary',
                organizationId = $state.params.id;

            scope.addNewRow = _.partial(addNewRow, $state.params.toolType);

            ToolsDefinitionService.fetch().then(function (definition) {
                scope.caption = $state.params.presetType ?
                    getPresetCaption(definition) :
                    getToolCaption(definition, $state.params.toolType);
            });

            function addNewRow(toolType) {
                if (toolType && toolType.toLowerCase() === 'all') {
                    aflToolTypeModal.open().then(addNewRow);
                } else {
                    $rootScope.$broadcast(eventName, {toolType: toolType, mode: 'ADD', organizationId: organizationId});
                }
            }

            function getPresetCaption(definition) {
                return definition[$state.params.toolType].groups.find(function (g) {
                        return g.id === $state.params.presetType
                    }).name + ' Preset';
            }

            function getToolCaption(definition, toolType) {
                return definition[toolType] ?
                    definition[toolType].name : 'New Tool';
            }
        }
    }
}());